import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { GalleryService } from "../../services/gallery.service";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: "app-solutions-details",
    templateUrl: "./solutions-details.component.html",
    styleUrls: ["./solutions-details.component.scss"],
})
export class SolutionsDetailsComponent implements OnInit {
    gallery: any;
    category_name: any;

    constructor(
        private router: ActivatedRoute,
        private galleryservice: GalleryService,
        private modalCtrl: NgbModal
    ) {
        var id = this.router.snapshot.params.id;
        console.log(id);
        this.getImagesDatas(id);
    }
    page: number = 1;

    ngOnInit(): void {}
    getImagesDatas(id: any) {
        console.log("pawan");
        this.galleryservice.getgallery(id).subscribe((res: any) => {
            console.log(res.data);
            this.gallery = res.data;
            this.category_name = res.data[0].category_name;
        });
    }


    modalDismiss() {
        this.modalCtrl.dismissAll()
      }
    
      showi: boolean = true;
    
      close() {
        this.showi = false;
      }
      modalimage1: any;
      modalimage: any = [];
      zoom(openmodal:any, image:any, img:any) {
        console.log( img);
        this.modalimage1 = img.image;
        console.log(this.modalimage1);
        this.modalimage = image[0].image;
        console.log( this.modalimage);
        this.showi = true;
        this.modalCtrl.open(openmodal, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'l' })
      }
    
    

}
