import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { DomSanitizer } from '@angular/platform-browser';
import { GalleryService } from '../../services/gallery.service';

@Component({
  selector: 'app-home-two',
  templateUrl: './home-two.component.html',
  styleUrls: ['./home-two.component.scss']
})
export class HomeTwoComponent implements OnInit {
  gallery: any;
  events: any;
  homevideos: any;
  page: number = 1;
  public blogpost: any;
  constructor(private gallerys: GalleryService, private sanitizer: DomSanitizer,) {
    this.getImagesDatas();
    this.getHomeVideos();
    this.getvideoData();
    this.getEventDatas();
  }
  dateTime: Date
  ngOnInit(): void {
    this.dateTime = new Date()
  }
  getImagesDatas() {
    console.log("pawan");
    this.gallerys.getgallerycategory().subscribe((res: any) => {
      console.log(res.data);
      this.gallery = res.data;
      // location.reload();
    })
  }
  getEventDatas() {
    console.log("pawan");
    this.gallerys.getEventData().subscribe((res: any) => {
      console.log(res.data);
      this.events = res.data;
      // location.reload();         
    })
  }
  getHomeVideos() {
    console.log("pawan");
    this.gallerys.getHomeVideoData().subscribe((res: any) => {
      console.log(res.data);
      this.homevideos = res.data;
      this.homevideos.map((res: any) => {
        res.banner_url = this.sanitizer.bypassSecurityTrustResourceUrl(res.banner_url)
      })
    }, error => {
    })
  }
  getvideoData() {
    this.gallerys.getvideourldata().subscribe((res: any) => {
      console.log(res.data);
      this.blogpost = res.data
      this.blogpost.map((res: any) => {
        res.banner_url = this.sanitizer.bypassSecurityTrustResourceUrl(res.banner_url)
      })
    })
  }
  homeSlides: OwlOptions = {
    animateOut: 'slideOutDown',
    animateIn: 'slideInDown',
    items: 1,
    loop: true,
    autoplay: true,
    dots: false,
    nav: true,
    autoHeight: true,
    autoplaySpeed: 800,
    mouseDrag: false,
    autoplayHoverPause: true,
    navText: [
      "<i class='flaticon-left-arrow'></i>",
      "<i class='flaticon-next-1'></i>"
    ],
    responsive: {
      0: {
        items: 1,
      },
      576: {
        items: 1,
      },
      768: {
        items: 1,
      },
      1200: {
        items: 1,
      }
    }
  }
  teamSlides: OwlOptions = {
    loop: true,
    margin: 20,
    dots: true,
    autoplay: true,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1,
      },
      575: {
        items: 2,
      },
      576: {
        items: 2,
      },
      768: {
        items: 3,
      },
      992: {
        items: 3,
      },
      1200: {
        items: 4,
      }
    }
  }
  clientWrap: OwlOptions = {
    loop: true,
    margin: 30,
    nav: false,
    mouseDrag: true,
    items: 1,
    dots: false,
    autoHeight: true,
    autoplay: true,
    smartSpeed: 800,
    autoplayHoverPause: true,
    center: false,
    responsive: {
      0: {
        items: 1,
        margin: 10,
      },
      576: {
        items: 1,
      },
      768: {
        items: 2,
        margin: 20,
      },
      992: {
        items: 2,
      },
      1200: {
        items: 2,
      }
    }
  }
}