<app-navbar2></app-navbar2>
<br>
<br>
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <img src="" alt="">
        </div>
    </div>
    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>
<section class="signup-area ptb-100" style="background-image: url(assets/images/gallery/12.png);">
    <div class="container">
        <div class="signup-form" style="border: 5px solid #d3d3d3">
            <div class="section-tittle text-center">
                <h2 class="text" style="font-size: 38px; color: rgb(236, 17, 17); font-weight: 500">
                    వాలంటీర్ ఫర్ బలరామ సైనిక్ </h2>
                <p class="text" style="text-align: center; color:black; font-size: 22px;  line-height: 32px;">సమాజంలో
                    మంచి మార్పు తీసుకు రావడం కోసం బలరామ క్రిష్ణ గారితో కలిసి ముందడుగు వెయ్యండి
                </p>
            </div>
            <form (ngSubmit)="submitupdate()" [formGroup]="submitupdateform">
                <div class="row">
                    <div class="col-md-12 col-sm-12">
                        <div class="form-group">
                            <input class="form-control text" type="text" name="name" placeholder="పేరు"
                                formControlName="name" autocomplete="off" required>
                        </div>
                    </div>
                    <div class="col-md-12 col-sm-12" style="padding-top: 10px;">
                        <div class="form-group">
                            <input class="form-control text" type="tel" name="age" placeholder="వయస్సు"
                                formControlName="age" autocomplete="off" required maxlength="2" minlength="2"
                                class="form-control" (keypress)="numberOnlyValidation($event)"
                                onkeyup="this.value = this.value.replace(/[^a-zA-Z0-9]/g, '')">
                        </div>
                    </div>
                    <div class="col-md-12 col-sm-12" style="padding-top: 10px;">
                        <div class="form-group">
                            <input class="form-control text" type="tel" name="phnum" placeholder="ఫోన్ నెంబర్"
                                formControlName="phnum" autocomplete="off" required maxlength="10" minlength="10"
                                class="form-control" (keypress)="numberOnlyValidation($event)"
                                onkeyup="this.value = this.value.replace(/[^a-zA-Z0-9]/g, '')">
                        </div>
                    </div>
                    <div class="col-md-12 col-sm-12" style="padding-top: 10px;">
                        <div class="form-group">
                            <input class="form-control text" type="text" name="mandalam" placeholder="మండలం"
                                formControlName="mandalam" autocomplete="off" required>
                        </div>
                    </div>
                    <div class="col-lg-12 col-sm-12" style="padding-top: 10px;">
                        <div class="form-group">
                            <input type="text" class="form-control " id="inputLastName1" formControlName="village"
                                required placeholder="విలేజ్" autocomplete="off" />
                        </div>
                    </div>
                    <div class="col-lg-12 col-sm-12" style="padding-top: 10px;">
                        <div class="form-group">
                            <input type="tel" class="form-control " id="inputLastName1" formControlName="aadhar"
                                placeholder="ఆధార్ నంబర్     (ఆప్షనల్)" autocomplete="off" maxlength="12" minlength="12"
                                class="form-control" (keypress)="numberOnlyValidation($event)"
                                onkeyup="this.value = this.value.replace(/[^a-zA-Z0-9]/g, '')" />
                        </div>
                    </div>
                    <div class="col-md-12 col-sm-12" style="padding-top: 10px;">
                        <div class="form-group">
                            <input class="form-control" type="text" name="profession" placeholder="వృత్తి"
                                formControlName="profession" autocomplete="off" required>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6" style="padding-top: 10px;">
                        <input class="input-file" type="file" id="formFile" accept="image/x-png,image/jpeg"
                            formControlName="imageurl" (change)="onImageChangedata($event)" placeholder="Image" /><br />
                        <span style="padding-top: 10px;" *ngFor="let im of imagesData">
                            <img src="{{ im.reviewimg }}" style="height: 50px; width: 50px" />
                        </span>
                    </div>
                    <div class="col-lg-6 col-md-6" style="padding-top: 10px;">
                    </div>
                    <!-- <div class="col-md-12 col-sm-12 col-xs-12" style="padding-top: 10px;">
                        <div class="privecy-txt">
                             <input type="checkbox" id="chb1" formControlName="chb1" autocomplete="off" required> --
                            <input type="checkbox" id="cb1" ng-model="check" ng-change="checkAll(check)" ng-true-value="YES" ng-false-value="NO" required />
                            <label class="text" style="font-size: 18px;">మీరు బలరామ క్రిష్ణ గారి<a
                                    routerLink="/balaramakrishna">&nbsp; కోసం పనిచేయాలనుకుంటున్నార</a></label>
                        </div>
                    </div> -->
                    <div class="col-12 text-center" style="padding-top: 10px;">
                        <button type="submit" class="box-btn signup-btn"
                            [disabled]="submitupdateform.invalid">Submit</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>