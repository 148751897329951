import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { GalleryService } from '../../services/gallery.service';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss']
})
export class BlogDetailsComponent implements OnInit {
  blogdetails: any
  constructor(private router: ActivatedRoute, private sanitizer: DomSanitizer, private bannerservice: GalleryService, private routerp: Router) {
    var id = this.router.snapshot.params.id
    console.log(id);
    this.getvideourldatadetails(id)
  }
  page: number = 1;


  ngOnInit(): void {
  }

  getvideourldatadetails(id: any) {
    this.bannerservice.getvideourldatadetails(id).subscribe((res: any) => {
      console.log(res.data);
      this.blogdetails = res.data;
      this.blogdetails.map((res: any) => {
        res.banner_url = this.sanitizer.bypassSecurityTrustResourceUrl(res.banner_url)
      })
    }, error => {
    })
  }
}
