<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12" style="padding: 0;">

      <ngb-carousel>
        <ng-template ngbSlide *ngFor="let event of slides">
          <img class="sf" style="margin-top: 145px;" [src]="event.image" alt="Random slide">
          <div class="carousel-caption">
            <!-- <h1 style="font-size: 55px;font-weight: bold;">{{ event.heading }}</h1> -->
            <!-- <p [innerText]="event.eventDescription"></p> -->
          </div>
        </ng-template>
      </ngb-carousel>
    </div>
  </div>
</div>