<app-navbar></app-navbar>
<section class="slider-area-2">
    <app-banners></app-banners>
</section>
<div class="info-area pb-70">
    <div class="container">
        <div class="section-title" id="mission">
            <span style="font-weight: 500;font-family: Ramabhadra,sans-serif !important;font-size: 34px;">జనసేన
                మిషన్</span>
            <h2 class="texts" style="font-weight: 500;font-family: Ramabhadra, sans-serif !important;font-size: 24px;">

                ప్రజా సేవకోసం, ధర్మబద్ధమైన న్యాయబద్ధమైన

                మంచి సమాజ స్థాపనకోసం

            </h2>
        </div>
        <div class="row">
            <div class="col-lg-4 col-sm-6"
                *ngFor="let item of homevideos | paginate: { itemsPerPage: 6, currentPage: page }  ">
                <div class="single-info">
                    <div class="resp-container">
                        <iframe [src]="item.banner_url" class="resp-iframe" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen class="img-fluid"
                            style="width:474px;height:251px; position: relative; top: 4px;" id="FrameID"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="home-service-area pb-70">
    <div class="container">
        <div class="section-title" id="mission">
            <span class="text" style="font-size: 38px; color: rgb(236, 17, 17) ;text-align: center;"> మా రాజకీయ ప్రవేశం</span>
            </div>
            <object data="assets/images/team/FINAL.pdf" type="application/pdf" width="100%" height="550px">               
              </object>
            <br>
            <div class="row">
                <div class="col-lg-12" style="text-align: center;">
                <!-- <a href="assets/images/team/FINAL.pdf" target="_blank">మా రాజకీయ ప్రవేశం
                    తెలుసుకోవాలి అనుకుంటే క్లిక్ చేయండి</a> -->
                    <!-- <iframe src="assets/images/team/FINAL.pdf" width="100%" height="100%" ></iframe> -->               
            </div>
        </div>
    </div>
</section>
<section class="home-service-area pb-70">
    <div class="container">
        <div class="section-title" id="mission">
            <span class="text" style="font-size: 38px; color: rgb(236, 17, 17)">గ్యాలరీ</span>
            <h2 class="texts" style="font-weight: 500;font-family: Ramabhadra, sans-serif !important;font-size: 24px;">
                స్థానికంగా అవసరాలకి అందుబాటులో ఉంటాం.
            </h2>
        </div>
        <div class="row">
            <div class="col-lg-3 col-sm-6"
                *ngFor="let item of gallery | paginate: { itemsPerPage: 8, currentPage: page }  ">
                <div class="single-services">
                    <a routerLink="/gallerydetails/{{item.id}}" class="image-popup">
                        <div class="container">
                            <img [src]="item.image" class="img-fluid"
                                style="box-shadow: 0 0 0 4px rgb(144 144 144 / 55%);" alt="">
                            <div class="content">
                                <h3 class="text">
                                    {{item.categoryname}}
                                </h3>
                            </div>
                        </div>
                    </a>
                    <div class="service-content">
                    </div>
                </div>
            </div>
            <section class="technology-area ptb-100 bg-color">
                <div class="section-title" id="mission">
                    <span class="text" style="font-size: 38px; color: rgb(236, 17, 17)">ప్రజల సంక్షేమం కోసం!</span>
                    <h2 class="texts" style="
                font-weight: 500;
                font-family: Ramabhadra, sans-serif !important ;
                font-size: 24px;">
                        ధర్మబద్ధంగా, న్యాయబద్ధంగా అన్ని కులాలకు, మతాలకు, వర్ణాలకు, వర్గాలకు ఆమోదయోగ్యమైన పరిపాలన కోసం
                        భావితరాల భవిష్యత్ కోసం, ఆంధ్రరాష్ట్ర ప్రజల అభివృద్ధి కోసం జనసేన పార్టీకి దయచేసి ఒక్క అవకాశం
                        ఇవ్వండి ప్లీజ్!
                    </h2>
                </div>
                <div class="container">
                    <div class="row align-items-center choose-c">
                        <div class="col-lg-6">
                            <div class="choose-img">
                                <img src="assets/images/team/750.png" alt="choose" />
                                <div class="technology-video">
                                    <a target="_blank" href="https://youtu.be/tdDniyd18UQ"
                                        class="video-btn popup-youtube"><i class="bx bx-play"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="technology-content">
                                <p class="text" style="text-align: justify; color:black; line-height: 32px;">
                                    “రాజానగరం నియోజకవర్గం ప్రజల అభివృద్ధి ధ్యేయంగా, ప్రజాసేవే ప్రధమ -కర్తవ్యంగా, ప్రజల
                                    కష్టాల్లో ఆపదల్లో అండగా, స్థానిక నాయకులుగా, నిస్వార్థంగా, ప్రజా సంక్షేమమే జీవిత
                                    లక్ష్యంగా... అన్నీ వదలి. మీ అందరి కోసం నిత్యం ప్రజల మధ్య ఉంటూ, ప్రజాసేవకే జీవితాన్ని
                                    అంకితం చేసి జనసేన పార్టీ తరుపున రాజానగరం నియోజకవర్గ ప్రజల కొరకు : నిరంతరం సేవ చేయడమే
                                    లక్ష్యంగా......
                                <p class="text" style="text-align: justify; color:black;  line-height: 32px;">
                                    1. నిరుద్యోగుల కొరకు అధునాతన సదుపాయాలుతో కంప్యూటర్ ట్రైనింగ్ ఇన్స్టిట్యూట్ ఏర్పాటు
                                    చేసి ఉచితంగా శిక్షణనిచ్చి. యువతకు ఉద్యోగ, ఉపాధి అవకాశాలు కల్పించడం.
                                </p>
                                <p class="text" style="text-align: justify; color:black;  line-height: 32px;">
                                    2. యువతకు స్కిల్ డెవలప్ మెంట్ సెంటర్లు ఏర్పాటు చేసి, శిక్షణ మరియు సర్టిఫికెట్ ఇచ్చి
                                    ఉద్యోగ, ఉపాధి అవకాశాలు కల్పించడం.
                                </p>
                                <a routerLink="/home-one" class="box-btn text" style="font-size: 24px;">మరిన్ని</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div class="jsp_wrapper pb-100" id="principles_wrapper">
                <div class="principles_container">
                    <div class="principle p1">
                        <div class="principle_content">
                            <h2 class="text" style="font-size: 25px; font-weight: 400">
                                కులాలను కలిపే<br />ఆలోచనా విధానం
                            </h2>
                        </div>
                    </div>
                    <div class="principle p2">
                        <div class="principle_content">
                            <h2 class="text" style="font-size: 25px; font-weight: 400">
                                మతాల ప్రస్తావన<br />లేని రాజకీయం
                            </h2>
                        </div>
                    </div>
                    <div class="principle p3">
                        <div class="principle_content">
                            <h2 class="text" style="font-size: 25px; font-weight: 400">
                                భాషలను గౌరవించే<br />సంప్రదాయం
                            </h2>
                        </div>
                    </div>
                    <div class="principle p4">
                        <div class="principle_content">
                            <h2 class="text" style="font-size: 25px; font-weight: 400">
                                సంస్కృతులను<br />కాపాడే సమాజం
                            </h2>
                        </div>
                    </div>
                    <div class="principle title_cont web">
                        <div class="principle_content clearfix">
                            <div class="count">7</div>
                            <div class="text">
                                <span>జనసేన </span>సిద్ధాంతాలు
                            </div>
                        </div>
                    </div>
                    <div class="principle p5">
                        <div class="principle_content">
                            <h2 class="text" style="font-size: 25px; font-weight: 400">
                                ప్రాంతీయతను విస్మరించని<br />జాతీయవాదం
                            </h2>
                        </div>
                    </div>
                    <div class="principle p6" style="width: 50%">
                        <div class="principle_content">
                            <h2 class="text" style="font-size: 25px; font-weight: 400">
                                అవినీతిపై రాజీలేని పోరాటం
                            </h2>
                        </div>
                    </div>
                    <div class="principle p7" style="width: 50%">
                        <div class="principle_content">
                            <h2 class="text" style="font-size: 25px; font-weight: 400">
                                పర్యావరణాన్ని పరిరక్షించే<br />అభివృద్ధి ప్రస్థానం
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
            <section class="home-case ptb-100">
                <div class="section-title" id="mission">
                    <span style="
        font-weight: 500;
        font-family: Ramabhadra, sans-serif !important ;
        font-size: 34px;">ఈవెంట్స్</span>
                    <h2 class="texts" style="
    font-weight: 500;
    font-family: Ramabhadra, sans-serif !important ;
    font-size: 24px;">ప్రజాస్వామ్యంలో ప్రజలు బాగుపడాలంటే రాజకీయ జవాబుదారీతనం కావాలి </h2>
                </div>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="boards">
                                <h1 class="board__heading" style="text-align:center"> News Blog</h1>
                                <div style="overflow-y:scroll; height:347px;padding: 10px;">
                                    <div class="card" style="padding: 10px;    margin-top: 12px;"
                                        *ngFor="let item of blogpost">
                                        <div class="container">
                                            <h4 style="
                              font-weight: 500;
                              font-family: Ramabhadra, sans-serif !important ;
                              font-size: 24px;                             
                              "><a style="color: #000;" routerLink="/blog-details/{{item.id}}">{{item.titlename}}</a>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                                <div class="container">
                                    <div class="row" style="overflow:auto">
                                    </div>
                                </div>
                                <section class="board__news">
                                </section>
                            </div>
                        </div>
                        <div class="col-lg-8">
                            <div class="boards">
                                <h1 class="board__heading" style="text-align:center">
                                    {{dateTime | date}} (Events Schedules)
                                </h1>
                                <div class="container">
                                    <div class="row">
                                        <div class="[col-lg-12 ]">
                                            <div style="overflow-y:scroll; height:347px;padding: 10px;">
                                                <ul class="event-list">
                                                    <h2 *ngIf="events.length==0" style="color:red;text-align: center;">
                                                        No Schedules Today</h2>
                                                    <li *ngFor="let item of events">
                                                        <time datetime="2014-07-20">
                                                            <span class="day">{{item.eventstart_time }} </span>
                                                            <span class="day">{{item.eventend_time}}</span>
                                                        </time>
                                                        <img alt="Independence Day" src="{{item.image}}" />
                                                        <div class="info">
                                                            <h2 class="title">{{item.event_nm}}</h2>
                                                            <p class="desc">{{item.event_addrress}}</p>
                                                        </div>
                                                        <div class="social">
                                                            <ul>
                                                                <li class="facebook" style="width:33%;"><a
                                                                        target="_blank"
                                                                        href="https://www.facebook.com/people/Balarama-Krishna-Battula/100082395607577/"><span
                                                                            class="fa fa-facebook"></span></a></li>
                                                                <li class="twitter" style="width:34%;"><a
                                                                        target="_blank"
                                                                        href="https://mobile.twitter.com/battulabalarama"><span
                                                                            class="fa fa-twitter"></span></a></li>
                                                                <li class="google-plus" style="width:33%;"><a
                                                                        target="_blank"
                                                                        href="mailto:contact@battulabalaramakrishna.com"><span
                                                                            class="fa fa-google-plus"></span></a></li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <app-pricing></app-pricing>