<app-navbar2></app-navbar2> <br /><br />
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <img src="" alt="" />        
        </div>
    </div>
    <div class="page-shape">
        <div class="shape1">
            <img src="assets/images/shape/1.png" alt="shape" />
        </div>
        <div class="shape3">
            <img src="assets/images/shape/3.png" alt="shape" />
        </div>
        <div class="shape4">
            <img src="assets/images/shape/4.png" alt="shape" />
        </div>
        <div class="shape5">
            <img src="assets/images/shape/5.png" alt="shape" />
        </div>
        <div class="shape6">
            <img src="assets/images/shape/6.png" alt="shape" />
        </div>
    </div>
</div>
<section class="technology-area ptb-100 bg-color">
    <div class="section-title" id="mission">
        <span class="text" style="font-size: 38px; color: rgb(236, 17, 17)">ఒక్క సారి గాజు గ్లాసు  గుర్తు పై ఓటెయ్యండి-
            ప్రజాపాలనకు అవకాశం ఇవ్వండి...
        </span>
        <h2 class="texts" style="
                font-weight: 500;
                font-family: Ramabhadra, sans-serif !important ;
                font-size: 24px;
            ">
            మీ ప్రతి కష్టంలో తోడుగా ఉంటాం.
        </h2>
    </div>
    <div class="container">
        <div class="row align-items-center choose-c">
            <!-- <div class="col-lg-6">
                <div class="choose-img">
                    <img src="assets/images/choose-img.jpg" alt="choose" />

                    <div class="technology-video">
                        <a
                            href="https://www.youtube.com/watch?v=hBNDmHzCZug"
                            class="video-btn popup-youtube"
                            ><i class="bx bx-play"></i
                        ></a>
                    </div>
                </div>
            </div> -->
            <div class="col-lg-12">
                <div class="technology-content">
                    <p class="text" style="
                            text-align: justify;
                            color: black;
                            line-height: 36px;
                        ">
                        “బలరామకృష్ణ” అను నేను ఆంధ్రప్రదేశ్ రాష్ట్రంలో తూ॥ గో॥
                        జిల్లా, కోరుకొండ మండలం, గాదరాడ గ్రామంలో కీ॥శే॥ బత్తుల
                        గంగారావు, శ్రీమతి గోవిందమ్మ పుణ్యదంపతులకు మూడవ సంతానంగా
                        రైతు కుటుంబంలో మారుమూల గ్రామం మహాశివరాత్రి పర్వదినాన,
                        తెల్లవారుజామున బ్రహ్మీముహూర్తంలో 1973 సం॥లో
                        జన్మించినాను. బాల్యం నుండి వ్యవసాయంలో మా నాన్న గారికి
                        సహాయకుడిగా తోడు ఉంటూ చదువుకునేవాడిని.
                    </p>
                    <!-- <p
                        class="text"
                        style="
                            text-align: justify;
                            color: black;
                            line-height: 36px;
                        "
                    >
                        అదే గ్రామంలో 10వ తరగతి వరకూ చదువుకుని చదువులో ఒక్కసారి
                        పాఠం వింటే పరీక్షలు వ్రాసే జ్ఞానం కలిగి ఉండేవాడిని. కాని
                        వ్యవసాయపనులు ఒత్తిడి వల్ల చదువు పూర్తి చేయలేక పోయాను.
                        చదువు ఆపేసి వ్యవసాయం చేస్తూ కల్లు, సారాయి, బ్రాంది,
                        చుట్ట, బీడి, సిగరెట్ లాంటి చెడు వ్యసనాలకు అలవాటుపడ్డాను.
                    </p> -->
                    <!-- <a routerLink="/contact" class="box-btn text" style="font-size: 24px;">మరిన్ని</a> -->
                </div>
            </div>
        </div>
    </div>
</section>
<section class="services-details-area" style="background: #f5faff">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="services-img mb">
                    <img style="
                            filter: drop-shadow(
                                0.35rem 0.35rem 0.4rem rgba(143, 137, 137, 0.5)
                            );
                        " src="assets/images/maa-gurinchi.jpg" />
                </div>
            </div>
            <div class="col-12">
                <div class="services-details-text">
                    <span class="text" style="font-size: 38px; color: rgb(236, 17, 17)">ప్రజల అభివృద్ధి కోసం జనసేన
                        పార్టీకి దయచేసి ఒక్క అవకాశం ఇవ్వండి ప్లీజ్!</span>
                    <h2 class="texts" style="
                            font-weight: 500;
                            font-family: Ramabhadra, sans-serif !important ;
                            font-size: 24px;
                        ">
                        ఒక్క సారి గాజు గ్లాస్ గుర్తు పై ఓటెయ్యండి-
                        ప్రజాపాలనకు అవకాశం ఇవ్వండి...
                    </h2>
                    <p class="text" style="
                            text-align: justify;
                            color: black;
                            line-height: 36px;
                        ">
                        వై ఎస్. ఆర్. సి. పి పార్టీ తో మొదలైన బత్తుల బలరామ కృష్ణ గారి రాజకీయ ప్రయాణం ఆ పార్టీ వల్ల
                        ప్రజలకి ఎటువంటి లబ్ది చేకూరట్లేదు అన్న అసంతృప్తి తో ఆ పార్టీ నుండి బయటికి రావడం జరిగింది .ఇది
                        ఇలా ఉండగా జనసేన అధినేత అయినటువంటి కొణిదెల పవన్ కళ్యాణ్ గారి సిద్ధాంతాలు నచ్చి రాష్ట్ర ప్రజలకి
                        మంచి చెయ్యాలన్న ఆలోచనతో జనసేన పార్టీ లోకి బత్తుల బలరామకృష్ణగారు మరియు వారి సతీమణి బత్తుల
                        వెంకటలక్ష్మిగారు జనసేన పార్టీలో చేరారు. రాష్ట్ర అభివృద్ధే జనసేన ధ్యేయమని సంకల్పించిన బలరామ కృష్ణ
                        గారు ప్రజల సమస్యలు తెలుసుకోవటం కోసం మహాపాదయాత్ర కార్యక్రమానికి శ్రీకారం చుట్టారు.ఈ పాదయాత్ర లో
                        ప్రజల యొక్క సమస్యలు తెలుసుకుని వాటికి తగిన పరిష్కారాన్ని అందిస్తున్నారు .ప్రజా సేవలలో భాగంగా
                        అనారోగ్యంతో బాధపడుతున్నటువంటి చిన్నారి భూదేవి కి లక్షరూపాయలు ఆర్ధిక సాయాన్ని అందించి తనను
                        ప్రాణహాని నుండి కాపాడి ఆ తల్లితండ్రులకు ఆపద్భాంధవునిగా నిలిచారు . రాజానగరం నియోజక వర్గం ప్రజలకు
                        సరైన సమయంలో ప్రభుత్వ అంబులెన్సులు అందుబాటులో ఉండక ప్రజలు ఇబ్బంది పడుతున్నారని తెలియగానే
                        అనుకున్నదే తడవుగ తన ప్రజల కోసం బత్తుల బలరామ కృష్ణ గారు మరియు వారి సతీమణి వెంకటలక్ష్మి గారు
                        అంబులెన్సులను ప్రజల కోసం అందించారు.
                    </p>
                    <p class="text" style="
                            text-align: justify;
                            color: black;
                            line-height: 36px;
                        ">ఇదేకాక పర్యావరణ పరిరక్షణ కోసం 1.5 లక్షల జామ మొక్కలను తన నియోజక వర్గం ప్రజలకి అందించి పర్యావరణ
                        సంరక్షణకై తన వంతు కర్తవ్యాన్ని నిర్వర్తించారు . వరద వల్ల నష్టపోయిన బాధితులకు బత్తుల బలరామ కృష్ణ
                        గారు నిత్యా అవసరాలను మరియు తగిన సౌకర్యాలను అందచేసి వారిని ఆదుకున్నారు, శ్రీరంగపట్నంలోని రోడ్డు
                        ప్రమాదంలో సూరిశెట్టి గారు మరణించారు వారి కుటుంబానికి బలరామకృష్ణ గారు 50,000 ఆర్థిక సహాయాన్ని
                        అందచేశారు . ప్రజల సంక్షేమం లో భాగంగా బాలరామ కృష్ణ గారు మరియు వారి సతీమణి చేపట్టిన కార్యక్రమాల్లో
                        గాదరాడ లో నిర్మించినటువంటి శ్రీ శివశక్తి పీఠం ప్రత్యేకమైనది . ఇవే కాకుండా ప్రజా సేవలో భాగంగా, "
                        మెగా రక్తదాన శిబిరం,నా సేన కోసం నా వంతు కార్యక్రమం,రుద్ర హోమం, మృత్యుంజయ హోమం, మృత్యుంజయ
                        హోమం,రైతుల ధాన్యం కొనుగోలుకై పోరాటం , రుద్ర హోమం , జాతీయ రైతు దినోత్సవ సంబరాలు” వంటి ఎన్నో
                        కార్యక్రమాలు నిర్వహించి ఆదర్శవంతమైన నాయకుడిగా నిలుస్తు ప్రజలకి మంచి చేయాలని అన్న ఉద్దెసాన్నీ
                        అధికారం లేకుండానే నిరూపించుకున్నారు .

                        <button style="float: right" class="" style="font-size: 24px"
                            (click)="toggleDisplayDiv()">
                            మరిన్ని
                        </button>
                    </p>
                    <!-- <a routerLink="/contact" class="box-btn text" style="font-size: 24px;">మరిన్ని</a> -->
                    <p [hidden]="isShowDiv" class="text" style="
                            text-align: justify;
                            color: black;
                            line-height: 36px;
                        ">
                        ప్రజల అభివృద్ధి కోసం తాను అధికారం లోకి వస్తే పలు సంక్షేమ పతకాలను అమలు చేస్తానని హామీ ఇచ్చారు.
                        అందులో రాజానగరం నియోజకవర్గం ప్రజల అభివృద్ధి ధ్యేయంగా, ప్రజాసేవే ప్రధమ కర్తవ్యంగా, ప్రజల
                        కష్టాల్లో ఆపదల్లో అండగా, స్థానిక నాయకులుగా, నిస్వార్థంగా, ప్రజా సంక్షేమమే జీవిత
                        లక్ష్యంగా...నిరుద్యోగుల కొరకు అధునాతన సదుపాయాలుతో కంప్యూటర్ ట్రైనింగ్ ఇన్స్టిట్యూట్ ఏర్పాటు చేసి
                        ఉచితంగా శిక్షణనిచ్చి. యువతకు ఉద్యోగ, ఉపాధి అవకాశాలు కల్పించడం,

                        అన్నీ వదలి. ప్రజలందరి కోసం నిత్యం ప్రజల మధ్య ఉంటూ, ప్రజాసేవకే జీవితాన్ని అంకితం చేసి జనసేన
                        పార్టీ తరుపున రాజానగరం నియోజకవర్గ ప్రజల కొరకు: నిరంతరం సేవ చేయడమే లక్ష్యంగా,
                        ఇవేకాక రైతులకు నాణ్యమైన విత్తనాలు, సబ్సిడీ ఎరువులు, పురుగుల మందులు, వ్యవసాయ పరికరాలు, యంత్రాలు,
                        ఉచిత విద్యుత్, పంటలు కొనుగోలు, గిట్టుబాటు ధర కల్పించడం, సకాలంలో డబ్బులు చెల్లించడం, పంట నష్ట
                        పరిహారం. పలు రైతు ప్రోత్సాహకాలు అందే విధంగా చూస్తామని హామీ ఇచ్చారు.
                    </p>
                    <p [hidden]="isShowDiv" class="text" style="
                        text-align: justify;
                        color: black;
                        line-height: 36px;
                    "> యువతకు స్కిల్ డెవలప్ మెంట్ సెంటర్లు ఏర్పాటు చేసి, శిక్షణ మరియు సర్టిఫికెట్ ఇచ్చి ఉద్యోగ, ఉపాధి
                        అవకాశాలు కల్పించడం, గృహిణిలకు కుటీర పరిశ్రమలు, శిక్షణా కేంద్రాలు ఏర్పాటు చేసి, తగిన
                        శిక్షణయిచ్చి, ఆర్ధికంగా బలపడే విధంగా స్వయం ఉపాధి.. కల్పించడం,ప్రజల ఆరోగ్యం పట్ల శ్రద్ధతో
                        రెగ్యులర్ గా మెడికల్ క్యాంపులు నిర్వహించి, ఆధునిక వైద్య సదుపాయాలు అందించడం, బ్లడ్ డోనేషన్
                        క్యాంపులు నిర్వహించడం తద్వారా మన నియోజక వర్గానికి ఒక రక్తనిధి కేంద్రాన్ని ఏర్పాటు చేసి అత్యవసర
                        పరిస్థితుల్లో రక్తాన్ని ఉచితంగా అందించడం, విద్యార్థులకు ప్రాధమిక విద్య, ఉన్నత విద్య, విదేశీ
                        విద్య ఉచితంగా అందించడం, మరియు వారికి కావలసిన అన్ని సదుపాయాలు..
                        అందే విధంగా, కల్లుగీత, మత్సకార, నాయిబ్రాహ్మణ, విశ్వబ్రాహ్మణ, రజక, 'గవర, నెలను, కుమ్మరి వడ్డెర,
                        దేవాంగులు, పద్మశాలి, మొదలగు కులవృత్తుల వారికి. తాపిమేస్త్రీలు, పెయింటర్స్, ప్లంబర్స్,
                        ఎలక్ట్రిషియన్స్, మోటరు ఫీల్డ్, టైలర్స్ చేతి వృత్తుల శ్రామికులకు తాను అధికారం లోకి వస్తే తగిన
                        ప్రాధాన్యత లభించే విధంగా చూస్తామని హామీ ఇచ్చారు.

                        ఇవేకాక రైతులకు నాణ్యమైన విత్తనాలు, సబ్సిడీ ఎరువులు, పురుగుల మందులు, వ్యవసాయ పరికరాలు, యంత్రాలు,
                        ఉచిత విద్యుత్, పంటలు కొనుగోలు, గిట్టుబాటు ధర కల్పించడం, సకాలంలో డబ్బులు చెల్లించడం, పంట నష్ట
                        పరిహారం. పలు రైతు ప్రోత్సాహకాలు అందే విధంగా చూస్తామని హామీ ఇచ్చారు.
                        ప్రజలకు త్రాగునీరు, నాణ్యమైన రోడ్లు, పింఛన్లు, మంచి వైద్యం, డ్రైనేజీలు, గ్రామాల పరిశుభ్రత, వీధి
                        లైట్లు, నిరంతరం విద్యత్ సరఫరా అందేలా. ఇలాంటి పలు సంక్షేమ పధకాలు ప్రజలకు అందచేయడంలో అండగా ఉంటానని
                        తెలియచేసారు .
                    </p>
                </div>
            </div>
        </div>
        <div class="scrives-item-2 mt-4">
            <div class="row align-items-center">
                <div class="col-lg-4">
                    <div class="services-img mb-qc">
                        <img src="assets/images/team/570.png" alt="Image" />
                    </div>
                </div>
                <div class="col-lg-8">
                    <span class="text" style="font-size: 38px; color: rgb(236, 17, 17)">
                        రాజకీయ ప్రయాణం</span>
                    <!-- <h2
                        class="texts"
                        style="
                            font-weight: 500;
                            font-family: Ramabhadra, sans-serif !important ;
                            font-size: 24px;
                        "
                    >
                        ఎంత సుధీర్ఘమైన ప్రయాణమైనా సరే ఒక్క అడుగుతోనే
                        ప్రారంభమవుతుంది.
                    </h2> -->
                    <p class="text" style="
                            text-align: justify;
                            color: black;
                            line-height: 36px;
                        ">
                        జనసేన అధినేత శ్రీ కొణిదల పవన్ కళ్యాణ్ గారు ఆశయాలు
                        సిద్ధాంతాలు రాజకీయ విలువలు నచ్చి రాజానగరం నియోజకవర్గంలో
                        కోరుకొండ మండలం గాదరాడ గ్రామానికి చెందిన వ్యాపారవేత్త
                        ఆధ్యాత్మికవేత్త ఓం శివశక్తి పీఠం వ్యవస్థాపకులు శ్రీ
                        బత్తుల బలరామకృష్ణ గారు వారి సతీమణి వెంకటలక్ష్మి గారు
                        పవన్ కళ్యాణ్ గారు సమక్షంలో జనసేన పార్టీలో చేరడం
                        జరిగింది. పార్టీ శ్రేణుల మధ్య సమన్వయం ఎలా ఉండాలి
                        పార్టీని క్షేత్రస్థాయిలో ఎలా బలోపేతం చేయాలి అనే విషయంపై
                        భక్తులు బలరామకృష్ణ వారి సతీమణి వెంకటలక్ష్మి గారు కృషి
                        చేస్తున్నారు .
                    </p>
                    <p class="text" style="
                            text-align: justify;
                            color: black;
                            line-height: 36px;
                        ">
                        రాజనగరం నియోజకవర్గం 3 మండలాల్లో ఉచిత కంప్యూటర్ శిక్షణ
                        ద్వారా యువతకు ఉద్యోగ కల్పన ఏర్పాటుకు శ్రీకారం చుట్టిన మన
                        రాజానగరం నియోజకవర్గ జనసేన నాయకులు శ్రీ బత్తుల బలరామకృష్ణ
                        గారు. రాజానగరం నియోజకవర్గం జనసేన పార్టీ తరఫున ఉచిత
                        ఉద్యోగ అవకాశాలు కల్పిస్తామని మాటిస్తున్నాను అని
                        సభాముఖంగా తెలియజేశారు. నియోజకవర్గ స్థాయిలో మూడు మండలాలకు
                        మూడు కంప్యూటర్ ఇన్స్టిట్యూట్లు స్థాపించి ఒక్కొక్క
                        కంప్యూటర్ ఇన్స్టిట్యూట్ కు సుమారు 100 కు పైగా
                        కంప్యూటర్లతో మరియు సిబ్బందితో ఉచిత ప్రత్యేక శిక్షణ
                        అందించి వారికి ఉద్యోగ అవకాశాలు కల్పించే వరకు జనసేన
                        పార్టీ అండగా ఉంటుందని తెలియపరిచారు.
                    </p>
                </div>
            </div>
        </div>
        <div class="scrives-item-2 mt-4" style="padding-top: 20px">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <!-- <span
                        class="text"
                        style="font-size: 38px; color: rgb(236, 17, 17)"
                        > రాజకీయ ప్రయాణం</span
                    > -->
                    <h2 class="texts" style="
                            font-weight: 500;
                            font-family: Ramabhadra, sans-serif !important ;
                            font-size: 35px;
                        ">
                        ఎంత సుధీర్ఘమైన ప్రయాణమైనా సరే ఒక్క అడుగుతోనే
                        ప్రారంభమవుతుంది.
                    </h2>
                    <p class="text" style="
                            text-align: justify;
                            color: black;
                            line-height: 36px;
                        ">
                        రాజానగరం నియోజకవర్గ ప్రజలు వైద్య సహాయార్థం అత్యవసర
                        పరిస్థితుల్లో హాస్పిటల్ కి వెళ్లడానికి గవర్నమెంట్
                        అంబులెన్సులు సరైన సమయంలో అందుబాటు లేక తీవ్ర అవస్థలు
                        పడుతున్నారు. ఈ విషయంపై బత్తుల బలరామకృష్ణ గారు అకుంఠిత
                        దీక్షతో ఆలోచించి జనసేన పార్టీ ద్వారా ప్రజలకు ఉచిత
                        అంబులెన్స్ సేవలను అందించాలని నిశ్చయించుకున్నారు.
                        అనుకున్నదే తడవుగా విజయవంతంగా నా సేన కోసం నా వంతు' కమిటీ
                        కోఆర్డినేటర్ శ్రీమతి బత్తుల వెంకటలక్ష్మి గారు ప్రత్యేక
                        పూజలు చేయించి ఈ అంబులెన్స్ సేవలకు పచ్చ జెండా ఊపి
                        ప్రారంభించారు ఈ కార్యక్రమంలో నియోజకవర్గ నాయకులు,
                        జనసైనికులు, వీరమహిళలు పెద్ద ఎత్తున పాల్గొన్నారు.
                    </p>
                </div>
                <div class="col-lg-6">
                    <div class="services-img mb-qc">
                        <img src="assets/images/team/750.png" alt="Image" />
                    </div>
                </div>
            </div>
        </div>
        <p class="text" style="text-align: justify; color: black; line-height: 36px">
            రాజకీయాల్లో బలరామ కృష్ణ గారు రాణిస్తున్న విషయం తెలిసిందే,వారి సతి
            మణి కూడా భర్త యొక్క ఆశయాలకు ప్రోత్సహిస్తూ వెను వెంటే ఉంటూ పార్టీ
            కోసం చేపట్టిన కార్యకలాపాలలో పాల్గొంటున్నారు రాజానగరం నియోజకవర్గం లో
            ఘనంగా జాతీయ రైతు దినోత్సవం వేడుకలు,జనం కోసం జనసేన" మహాపాదయాత్ర మరియు
            ఉచిత అంబులెన్సు సేవలు, విద్యార్థులకు కంప్యూటర్ శిక్షణ తరగతులు
            చేపట్టారు, మరియు ప్రజల సంక్షేమం కోసం ఈ దంపతులు సారధి గా
            స్థాపించబడినదే ఓం శివ శక్తి పీఠం.
        </p>
        <div class="scrives-item-3 mt-4 pb-100"></div>
    </div>
</section>