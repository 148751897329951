
<app-navbar2></app-navbar2> <br /><br />
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <img src="" alt="" />
        </div>
    </div>
    <div class="page-shape">
        <div class="shape1">
            <img src="assets/images/shape/1.png" alt="shape" />
        </div>
        <div class="shape3">
            <img src="assets/images/shape/3.png" alt="shape" />
        </div>
        <div class="shape4">
            <img src="assets/images/shape/4.png" alt="shape" />
        </div>
        <div class="shape5">
            <img src="assets/images/shape/5.png" alt="shape" />
        </div>
        <div class="shape6">
            <img src="assets/images/shape/6.png" alt="shape" />
        </div>
    </div>
</div>
<section class="contact-area pt-100 pb-70">
    <div class="container">
        <div class="row" >    
            <div class="col-lg-4 col-sm-6" style="display:flex">
                <div class="single-contact">
                    <div class="icon">
                        <i class='bx bx-location-plus'></i>
                    </div>
                    <div class="content">
                        <h3>Address</h3>
                        <p>D.NO: 2-156 Gadharada village Government School opposite,Korukonda Mandalam East Godavari Dist</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6" style="display:flex">
                <div class="single-contact">
                    <div class="icon">
                        <i class='bx bx-location-plus'></i>
                    </div>
                    <div class="content">
                        <h3>Address</h3>
                        <p>Korukonda Village Beside H.P bunk Korukonda ,Korukonda Mandalam East Godavari Dist</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6" style="display:flex">
                <div class="single-contact">
                    <div class="icon">
                        <i class='bx bx-location-plus'></i>
                    </div>
                    <div class="content">
                        <h3>Address</h3>
                        <p>D.NO: 8-208 Beside State Bank of India Seethanagaram,Seethanagaram Mandalam East Godavari Dist Pin:533287</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-4" style="display:flex">
                <div class="single-contact">
                    <div class="icon">
                        <i class='bx bx-envelope'></i>
                    </div>
                    <div class="content">
                        <h3>Email</h3>
                        <p>contact@battulabalaramakrishna.com
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6" style="display:flex">
                <div class="single-contact">
                    <div class="icon">
                        <i class='bx bx-phone-call'></i>
                    </div>
                    <div class="content">
                        <h3>Phone</h3>
                        <p>+91 93849 99999</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-pricing></app-pricing>