<footer class="footer-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="content">
                    <div class="logo">
                        <a routerLink="/"><img src="assets/images/logo2.png" alt="logo" /></a>
                    </div>
                    <!-- <p>Balarama Krishna Battula Rajanagaram JanaSena Party Leader And Renowned Business Man.</p> -->
                    <div class="subscribe " style="padding-top:20px">
                        <h4>Join Newsletter</h4>
                        <form class="newsletter-form">
                            <input type="email" id="emails" class="form-control" placeholder="Your Email" name="EMAIL" required autocomplete="off">
                            
                            <button class="box-btn" type="submit"> <a href="mailto:contact@battulabalaramakrishna.com">Subscribe</a></button>
                        </form>
                    </div>
                    <ul class="social">
                        <li><a href="https://www.facebook.com/people/Balarama-Krishna-Battula/100082395607577/" target="_blank"><i class='bx bxl-facebook' ></i></a></li>
                        <li><a href="https://mobile.twitter.com/battulabalarama" target="_blank"><i class='bx bxl-twitter' ></i></a></li>
                        <li><a href="https://instagram.com/balaramakrishnabattula?igshid=OGQ2MjdiOTE=0" target="_blank"><i class='bx bxl-instagram' ></i></a></li>
                        <!-- <li><a href="#" target="_blank"><i class='bx bxl-pinterest' ></i></a></li> -->
                    </ul>
                </div>
            </div>
            <!-- 
            <div class="col-lg-3 col-md-6">
                <div class="content ml-15">
                    <h3>Our Service</h3>
                    <ul class="footer-list">
                        <li><a routerLink="/">Visual Design</a></li>
                        <li><a routerLink="/"> Development</a></li>
                        <li><a routerLink="/">QA & Testing</a></li>
                        <li><a routerLink="/">IT Management</a></li>
                        <li><a routerLink="/">Cyber Security</a></li>
                        <li><a routerLink="/">Wireless Connection</a></li>
                    </ul>
                </div>
            </div> -->
            <div class="col-lg-4 col-md-6" style="margin-right: 0%;">
                <div class="content contacts">
                    <h3 class="ml-40">Contact</h3>
                    <ul class="footer-list foot-social">
                        <li><i class="bx bxs-map"></i> D.NO: 2-156 Gadharada vIllage Government School opposite,Korukonda Mandalam East Godavari Dist </li>
                        <li><i class="bx bxs-map"></i> Korukonda Village Beside H.P bunk Korukonda ,Korukonda Mandalam East Godavari Dist </li>
                        <li><i class="bx bxs-map"></i> D.NO: 8-208 Beside State Bank of India Seethanagaram,Seethanagaram Mandalam East Godavari Dist Pin:533287</li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="content contacts">
                    <h3 class="ml-40">Contact</h3>
                    <ul class="footer-list foot-social">
                        <li><a href="tel:+919384999999"><i class="bx bx-phone"></i> +91 93849 99999</a></li>
                        <li><a href="mailto:contact@battulabalaramakrishna.com"><i class="bx bxs-envelope"></i>contact@battulabalaramakrishna.com</a></li>
                        <!-- <li><i class="bx bxs-map"></i> D.NO: 8-208 Beside State bank of india Seethanagara,,Seethanagaram Mandalam East Godavari Dist Pin:533287</li> -->
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="copy-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <!-- <ul class="menu"> -->
                        <!-- <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/about">About</a></li>
                        <li><a routerLink="/blog">Blog</a></li>
                        <li><a routerLink="/contact">Contact</a></li>
                    </ul> -->
                </div>
                <div class="col-lg-6">
                    <p>© purely owned by  <a href="https://battulabalaramakrishna.com/" target="_blank">Battula Balaramakrishna</a></p>
                </div>
            </div>
        </div>
    </div>
</footer>
<div class="go-top">
    <i class='bx bx-chevrons-up'></i>
    <i class='bx bx-chevrons-up'></i>
</div>