import { Component, OnInit } from '@angular/core';
import { GalleryService } from '../../services/gallery.service';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {
  gallery: any;

  constructor(private bannerservice: GalleryService) { }
  page: number = 1;

  ngOnInit(): void {
  this.getImagesDatas();

  }


  getImagesDatas(){
    console.log("pawan");
    this.bannerservice.getgallerycategory().subscribe((res: any) => {
      console.log(res.data);
      this.gallery = res.data;
      // location.reload();
      
    })
  }


}
