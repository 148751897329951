import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import Swal from "sweetalert2";
import { GalleryService } from '../../services/gallery.service';
@Component({
    selector: 'app-pricing',
    templateUrl: './pricing.component.html',
    styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {
    imagesData: any = [];
    serviceForm: FormGroup;
    intern: any;
    submitted: boolean = false;
    // jobs: any
    trainings: boolean = true
    constructor(
        private formBuilder: FormBuilder,
        private service: GalleryService
    ) { }
    ngOnInit(): void {
        localStorage.setItem("urlname", "careers");
        this.serviceForm = this.formBuilder.group({
            name: ["", [Validators.required]],
            age: ["", [Validators.required]],
            phonenumber: ["", [Validators.required]],
            mandal: ["", [Validators.required]],
            village:["",[Validators.required]],
            occupation: ["", [Validators.required]],
            remarks: ["", [Validators.required]],
            imageurl: [""],
            // img_2: [""],
            // img_3: [""],
        });
        // this.job()
    }
    // onImageChange(e) {
    //     const reader = new FileReader();
    //     if (e.target.files && e.target.files.length) {
    //         const [file] = e.target.files;
    //         reader.readAsDataURL(file);
    //         reader.onload = () => {
    //             var imgFile = reader.result as string;
    //             console.log(e.target.files[0].name);
    //             var bfile_typeEmpl = e.target.files[0].name.split(".");
    //             var imgtype = bfile_typeEmpl[1];
    //             this.imagedata.push({ reviewimg: imgFile, filetype: imgtype });
    //         };
    //     }
    // }
    get addUsersFormCnt() { return this.serviceForm.controls; }
    errorMessageAlert(message) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: message,
          showCloseButton: true,
          showCancelButton: false,
          showConfirmButton: true,
          timer: 3500
        })
      }
      onImageChange(e) {
        if (this.imagesData.length == 3) {
          this.service.errorMessageAlert('You Have To Add Four images Only');
          return;
        }
        const reader = new FileReader();
        if (e.target.files && e.target.files.length) {
          const [file] = e.target.files;
          reader.readAsDataURL(file);
          reader.onload = () => {
            var imgFile = reader.result as string;
            var bfile_typeEmpl = e.target.files[0].name.split('.');
            var imgtype = bfile_typeEmpl[1];
            var imagedata = {
              reviewimg: imgFile,
              filetype: imgtype,
            }
            this.imagesData.push(imagedata);
            // console.log(this.imagesData);
          };
        }
      }
      addUserSubmit() {
        // this.formubmit = true;
        // this.showSpinner = true;
        if (this.serviceForm.invalid) {
          return;
        } else {
          var addcate = {
            data: this.serviceForm.value,
            reviewImg: this.imagesData
          }
          // console.log(addcate)
          this.service.postcareers(addcate).subscribe(
            (res) => {
               
                Swal.fire(
                    "మీ సమస్యల నమోదు విజయవంతంగా తీసుకోబడింది!",
                    "Successful!",
                    "success"
                );
                this.serviceForm.reset();
                this.imagesData = [];
                // location.reload();
            }, error => {
            
          })
        }
      }






    submitservices() {
        console.log(this.serviceForm.value, this.serviceForm);
        if (this.serviceForm.invalid) {
            alert('please fill the details')
            // console.log("returned");
            return;
        } else {
            if (this.serviceForm.invalid) {
                return;
              } else {
                var addcate = {
                  data: this.serviceForm.value,
                  reviewImg: this.imagesData
                }
            // console.log(addcate);
            
                this.service.postcareers(addcate).subscribe(
                    (res) => {
                        // console.log(res);
                        Swal.fire(
                            "మీ సమస్యల నమోదు విజయవంతంగా తీసుకోబడింది!",
                            "Successful!",
                            "success"
                        );
                        this.serviceForm.reset();
                        this.imagesData = [];
                        // location.reload();
                    },
                    (error) => {
                        console.log(error);
                    }
                );           
        }
    }
}
numberOnlyValidation(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
    deleteImage(index) {
        // var index = $scope.reviewImg.indexOf(img)
        this.imagesData.splice(index, 1);
    }
    //target1
    // training() {
    //     this.trainings = false
    //     this.jobs = true
    //     this.intern = true
    // }
    // //target2
    // job() {
    //     this.trainings = true
    //     this.jobs = false
    //     this.intern = true
    // }
    // //target3
    // internship() {
    //     this.trainings = true
    //     this.jobs = true
    //     this.intern = false
    // }
}

