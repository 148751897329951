<app-navbar2></app-navbar2>
<br>
<br>
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <!-- <h2>About</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">సిద్ధాంతాలు</li>
            </ul> -->
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>
<section class="technology-area ptb-100 bg-color">
    <div class="section-title" id="mission">

        <span class="texts" style="
        font-weight: 500;
        font-family: Ramabhadra, sans-serif !important ;
        font-size: 27px; text-align: justify;">
            మా రాజకీయ ప్రవేశం పదవుల కోసం, పవర్ కోసం
            కాదు.
            ధర్మబద్ధంగా, న్యాయబద్ధంగా అన్ని కులాలకు, మతాలకు, వర్ణాలకు, వర్గాలకు ఆమోదయోగ్యమైన పరిపాలన కోసం
            భావితరాల భవిష్యత్ కోసం, ఆంధ్రరాష్ట్ర ప్రజల అభివృద్ధి కోసం జనసేన పార్టీకి దయచేసి ఒక్క అవకాశం
            ఇవ్వండి ప్లీజ్!</span>

        <h2 class="texts" style="
    font-weight: 500;
    font-family: Ramabhadra, sans-serif !important ;
    font-size: 24px;">

        </h2>
    </div>
    <div class="container">
        <div class="row align-items-center choose-c">
            <div class="col-lg-6">
                <div class="choose-img">
                    <div class="services-img mb-qc">
                        <img src="assets/images/team/choose-img (2).jpg" alt="Image" />
                    </div>
                </div>
            </div>
            <!-- <div class="col-lg-6">
                <div class="choose-img">
                    <img src="assets/images/choose-img.jpg" alt="choose" />
                    <div class="technology-video">
                        <a target="_blank" href="https://youtu.be/tdDniyd18UQ"
                            class="video-btn popup-youtube"><i class="bx bx-play"></i></a>
                    </div>
                </div>
            </div> -->
            <div class="col-lg-6">
                <div class="technology-content">
                    <p class="text" style="text-align: justify; color:black; line-height: 32px;">
                        “రాజానగరం నియోజకవర్గం ప్రజల అభివృద్ధి ధ్యేయంగా, ప్రజాసేవే ప్రధమ -కర్తవ్యంగా, ప్రజల
                        కష్టాల్లో ఆపదల్లో అండగా, స్థానిక నాయకులుగా, నిస్వార్థంగా, ప్రజా సంక్షేమమే జీవిత
                        లక్ష్యంగా... అన్నీ వదలి. మీ అందరి కోసం నిత్యం ప్రజల మధ్య ఉంటూ, ప్రజాసేవకే జీవితాన్ని
                        అంకితం చేసి జనసేన పార్టీ తరుపున రాజానగరం నియోజకవర్గ ప్రజల కొరకు : నిరంతరం సేవ చేయడమే
                        లక్ష్యంగా......

                    <p class="text" style="text-align: justify; color:black;  line-height: 32px;">
                        1. నిరుద్యోగుల కొరకు అధునాతన సదుపాయాలుతో కంప్యూటర్ ట్రైనింగ్ ఇన్స్టిట్యూట్ ఏర్పాటు
                        చేసి ఉచితంగా శిక్షణనిచ్చి. యువతకు ఉద్యోగ, ఉపాధి అవకాశాలు కల్పించడం.
                    </p>
                    <p class="text" style="text-align: justify; color:black;  line-height: 32px;">
                        2. యువతకు స్కిల్ డెవలప్ మెంట్ సెంటర్లు ఏర్పాటు చేసి, శిక్షణ మరియు సర్టిఫికెట్ ఇచ్చి
                        ఉద్యోగ, ఉపాధి అవకాశాలు కల్పించడం.
                    </p>
                    <p class="text" style="text-align: justify; color:black;  line-height: 32px;">
                        3. గృహిణిలకు కుటీర పరిశ్రమలు, శిక్షణా కేంద్రాలు ఏర్పాటు చేసి, తగిన శిక్షణయిచ్చి, ఆర్ధికంగా బలపడే
                        విధంగా స్వయం ఉపాధి.. కల్పించడం.
                    </p>
                    <p class="text" style="text-align: justify; color:black;  line-height: 32px;">
                        4. ప్రజల ఆరోగ్యం పట్ల శ్రద్ధతో రెగ్యులర్ గా మెడికల్ క్యాంపులు నిర్వహించి, ఆధునిక వైద్య సదుపాయాలు
                        అందించడం, బ్లడ్ డోనేషన్ క్యాంపులు నిర్వహించడం తద్వారా మన నియోజక వర్గానికి ఒక రక్తనిధి
                        కేంద్రాన్ని ఏర్పాటు చేసి అత్యవసర పరిస్థితుల్లో రక్తాన్ని ఉచితంగా అందించడం.
                    </p>
                    <p class="text" style="text-align: justify; color:black;  line-height: 32px;">
                        5. విద్యార్థులకు ప్రాధమిక విద్య, ఉన్నత విద్య, విదేశీ విద్య ఉచితంగా అందించడం, మరియు వారికి
                        కావలసిన అన్ని సదుపాయాలు.. అందే విధంగా......
                    </p>
                    <p class="text" style="text-align: justify; color:black;  line-height: 32px;">
                        6. కల్లుగీత, మత్సకార, నాయిబ్రాహ్మణ, విశ్వబ్రాహ్మణ, రజక, 'గవర, నెలను, కుమ్మరి వడ్డెర, దేవాంగులు,
                        పద్మశాలి, మొదలగు కులవృత్తుల వారికి. తాపిమేస్త్రీలు, పెయింటర్స్, ప్లంబర్స్, ఎలక్ట్రిషియన్స్,
                        మోటరు ఫీల్డ్, టైలర్స్ చేతి వృత్తుల శ్రామికులకు తగిన ప్రాధాన్యత లభించే విధంగా.......
                    </p>
                    <p class="text" style="text-align: justify; color:black;  line-height: 32px;">
                        7. రైతులకు నాణ్యమైన విత్తనాలు, సబ్సిడీ ఎరువులు, పురుగుల మందులు, వ్యవసాయ పరికరాలు, యంత్రాలు, ఉచిత
                        విద్యుత్, పంటలు కొనుగోలు, గిట్టుబాటు ధర కల్పించడం, సకాలంలో డబ్బులు చెల్లించడం, పంట నష్ట పరిహారం.
                        పలు రైతు ప్రోత్సాహకాలు అందే విధంగా.......
                    </p>
                    <p class="text" style="text-align: justify; color:black;  line-height: 32px;">
                        8. ప్రజలకు త్రాగునీరు, నాణ్యమైన రోడ్లు, పింఛన్లు, మంచి వైద్యం, డ్రైనేజీలు, గ్రామాల పరిశుభ్రత,
                        వీధి
                        లైట్లు, నిరంతరం విద్యత్ సరఫరా అందేలా... ఇలాంటి పలు సంక్షేమ పధకాలు ప్రజలకు అందచేయడంలో అండగా 'మేము
                        సైతం అంటూ.......
                    </p>
                    <p class="text" style="text-align: justify; color:black;  line-height: 32px;">
                        * ఒక్క సారి గాజు గ్లాసు గుర్తు పై ఓటెయ్యండి-
                        ప్రజాపాలనకు అవకాశం ఇవ్వండి...
                        బత్తుల బలరామకృష్ణ
                        శ్రీమతి బత్తుల వెంకటలక్ష్మి

                    </p>
                    <!-- <p class="text" style="text-align: justify; color:black;  line-height: 32px;">
                        2. యువతకు స్కిల్ డెవలప్ మెంట్ సెంటర్లు ఏర్పాటు చేసి, శిక్షణ మరియు సర్టిఫికెట్ ఇచ్చి
                        ఉద్యోగ, ఉపాధి అవకాశాలు కల్పించడం.
                    </p>
                    <p class="text" style="text-align: justify; color:black;  line-height: 32px;">
                        2. యువతకు స్కిల్ డెవలప్ మెంట్ సెంటర్లు ఏర్పాటు చేసి, శిక్షణ మరియు సర్టిఫికెట్ ఇచ్చి
                        ఉద్యోగ, ఉపాధి అవకాశాలు కల్పించడం.
                    </p>
                    <p class="text" style="text-align: justify; color:black;  line-height: 32px;">
                        2. యువతకు స్కిల్ డెవలప్ మెంట్ సెంటర్లు ఏర్పాటు చేసి, శిక్షణ మరియు సర్టిఫికెట్ ఇచ్చి
                        ఉద్యోగ, ఉపాధి అవకాశాలు కల్పించడం.
                    </p>
                    <p class="text" style="text-align: justify; color:black;  line-height: 32px;">
                        2. యువతకు స్కిల్ డెవలప్ మెంట్ సెంటర్లు ఏర్పాటు చేసి, శిక్షణ మరియు సర్టిఫికెట్ ఇచ్చి
                        ఉద్యోగ, ఉపాధి అవకాశాలు కల్పించడం.
                    </p> -->
                    <!-- <a routerLink="/home-one" class="box-btn text"
                        style="font-size: 24px;">మరిన్ని</a> -->
                </div>
            </div>
        </div>
    </div>
</section>