import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import Swal from "sweetalert2";
import { GalleryService } from '../../services/gallery.service';
@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {
  intern: any;
  jobs: any;
  imagesData: any[];
  imagesDatsa: any;
  submitupdateform: FormGroup;
  trainings: boolean = true;
  lonameData: any;
  gettabljcidata: any;
  getjcidata: any;
  getcategory: any;
  formubmit: boolean;
  constructor(
    private bannerservice: GalleryService,
    private formBuilder: FormBuilder
  ) { 
    this.submitupdateform = this.formBuilder.group({
      name: ['', [Validators.required]],
      age: ['', [Validators.required]],
      phnum: ['', [Validators.required]],
      mandalam: ['', [Validators.required]],
      village: ['', [Validators.required]],
      aadhar: ['',],
      profession: ['', [Validators.required]],
      chb1: ['chb1', [Validators.required]],
      imageurl: [''],
    });
  }
  // Footer style
  classname = 'footer-area footer-area-v1 footer-area-v3  bg_cover';
  ftlogo = "assets/images/logo.png";
  ftbg = "assets/images/banner/floor.png";
  ngOnInit(): void {
  
  }
  training() {
    this.trainings = false;
    this.jobs = true;
    this.intern = true;
  }
  //target2
  job() {
    this.trainings = true;
    this.jobs = false;
    this.intern = true;
  }
  //target3
  internship() {
    this.trainings = true;
    this.jobs = true;
    this.intern = false;
  }
  // onImageChangedata(e: any) {
  //   const reader = new FileReader();
  //   if (e.target.files && e.target.files.length) {
  //     const [file] = e.target.files;
  //     reader.readAsDataURL(file);
  //     reader.onload = () => {
  //       var imgFile = reader.result as string;
  //       var bfile_typeEmpl = e.target.files[0].name.split('.');
  //       var imgtype = bfile_typeEmpl[1];
  //       if (imgtype == 'jpg' || imgtype == 'png' || imgtype == 'jpeg') {
  //         this.imagesData = [];
  //         this.imagesData.push({ reviewimg: imgFile, filetype: imgtype });
  //       } else {
  //         alert('Only Images Allowed');
  //       }
  //     };
  //   }
  // }
  onImageChangedata(e) {
    const reader = new FileReader();
    if (e.target.files && e.target.files.length) {
      const [file] = e.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        var imgFile = reader.result as string;
        var bfile_typeEmpl = e.target.files[0].name.split('.');
        var imgtype = bfile_typeEmpl[1];
        if (imgtype == "jpg" || imgtype == "png" || imgtype == "jpeg") {
          this.imagesData = [];
          this.imagesData.push({ reviewimg: imgFile, filetype: imgtype })
          // console.log(this.imagesData);
        }
        else {
          alert("Only Images Allowed");
        }
      };
    }
  }
  numberOnlyValidation(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  submitupdate() {
    // this.submitupdateform.value.image = this.imagesData[0];
    // console.log(this.submitupdateform.value);
    this.formubmit = true;
    if (this.submitupdateform.invalid) {
      console.log(this.submitupdateform.value);
      
      return;
    } else {
      var addcate = {
        data: this.submitupdateform.value,
        reviewImg: this.imagesData
      }
      // console.log(addcate)
    this.bannerservice.submitupdate(addcate)
      .subscribe((res: any) => {
        this.statusChangeAlert('REGISTERED SUCCESFULLY');
        this.submitupdateform.reset({});
        this.imagesData = [];
        location.reload();
      });
    }
  }
  statusChangeAlert(message: any) {
    Swal.fire({
      icon: 'success',
      text: message,
      title: 'విజయవంతంగా నమోదు చేయబడింది',
      showConfirmButton: false,
      timer: 3000,
    });
  }
}