import { Component, OnInit } from '@angular/core';
import { GalleryService } from '../../services/gallery.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  page: number = 1;
  public blogpost: any;
  constructor(private sanitizer: DomSanitizer, private bannerservice: GalleryService) {

  }

  ngOnInit(): void {
    this.getvideoData();

  }


  getvideoData() {
    this.bannerservice.getvideourldata().subscribe((res: any) => {
      console.log(res.data);
      this.blogpost = res.data

      this.blogpost.map((res: any) => {
        res.banner_url = this.sanitizer.bypassSecurityTrustResourceUrl(res.banner_url)
      })
    })
  }


}
