<app-navbar2></app-navbar2>
<br>
<br>

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <img src="" alt="">

        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="blog-details-area ptb-100" *ngFor="let item of blogdetails">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-image">

                        <iframe [src]="item.banner_url" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen class="img-fluid" style="width:900px;height:474px" id="FrameID"></iframe>
                    </div>

                    <div class="article-content">
                        <div class="entry-meta">
                            <ul>                              
                                <li><span>Posted By:</span> <a class="texts" style="
                                    font-weight: 500;
                                    font-family: Ramabhadra, sans-serif !important ;
                                    font-size: 24px;
                                    " routerLink="/blog"> {{item.speaker}}</a></li>
                            </ul>
                        </div>
                        <h3 class="texts" style="
                        font-weight: 500;
                        font-family: Ramabhadra, sans-serif !important ;
                        font-size: 44px;
                        ">{{item.titlename}}</h3>
                        <p class="texts" style="
                            font-weight: 500;
                            font-family: Ramabhadra, sans-serif !important ;
                            font-size: 24px;
                            ">{{item.content}}</p>
                        <p class="texts" style="
                        font-weight: 500;
                        font-family: Ramabhadra, sans-serif !important ;
                        font-size: 24px;
                        ">{{item.content2}}</p>
                        <p class="texts" style="
                        font-weight: 500;
                        font-family: Ramabhadra, sans-serif !important ;
                        font-size: 24px;
                        ">{{item.content3}}</p>
                        <blockquote class="flaticon-quote" style="
                        font-weight: 500;
                        font-family: Ramabhadra, sans-serif !important ;
                        font-size: 30px;color: black;
                             ">
                            ప్రజాస్వామ్యంలో ప్రజలు బాగుపడాలంటే రాజకీయ జవాబుదారీతనం కావాలి.
                        </blockquote>
                        <p class="texts" style="
                        font-weight: 500;
                        font-family: Ramabhadra, sans-serif !important ;
                        font-size: 24px;
                        ">{{item.longconent}}</p>
                        <div class="row mb-3">
                            <div class="col-lg-4 col-sm-6 mt-4">
                                <div class="b-d-s-img">
                                    <img src="{{item.image2}}" alt="Image">
                                </div>
                            </div>

                            <div class="col-lg-4 col-sm-6 mt-4">
                                <div class="b-d-s-img">
                                    <img src="{{item.image3}}" alt="Image">
                                </div>
                            </div>

                            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0 mt-4">
                                <div class="b-d-s-img">
                                    <img src="{{item.image4}}" alt="Image">
                                </div>
                            </div>
                        </div>
                        <p class="texts" style="
                        font-weight: 500;
                        font-family: Ramabhadra, sans-serif !important ;
                        font-size: 24px;
                        ">{{item.longcontent}}</p>

                    </div>
                                  
                    <!-- <div class="article-share"> -->
                    <!-- <ul class="social">
                                <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                            </ul> -->
                    <!-- </div> -->
                    <!-- </div> -->

                    <!-- <div class="post-navigation"> -->
                    <!-- <div class="navigation-links">
                            <div class="nav-previous">
                                <a routerLink="/blog-details"><i class='bx bx-left-arrow-alt'></i> Prev Post</a>
                            </div>

                            <div class="nav-next">
                                <a routerLink="/blog-details">Next Post <i class='bx bx-right-arrow-alt'></i></a>
                            </div>
                        </div> -->
                    <!-- </div> -->

                    <!-- <div class="comments-area">
                        <h3 class="comments-title">2 Comments:</h3>

                        <ol class="comment-list">
                            <li class="comment">
                                <div class="comment-body">
                                    <footer class="comment-meta">
                                        <div class="comment-author vcard">
                                            <img src="assets/images/blog-details/comment-img-1.jpg" class="avatar" alt="image">
                                            <b class="fn">John Jones</b>
                                            <span class="says">says:</span>
                                        </div>

                                        <div class="comment-metadata">
                                            <a routerLink="/blog-details"><span>April  24, 2020 at 10:59 am</span></a>
                                        </div>
                                    </footer>

                                    <div class="comment-content">
                                        <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type.</p>
                                    </div>

                                    <div class="reply">
                                        <a routerLink="/blog-details" class="comment-reply-link">Reply</a>
                                    </div>
                                </div>

                                <ol class="children">
                                    <li class="comment">
                                        <div class="comment-body">
                                            <footer class="comment-meta">
                                                <div class="comment-author vcard">
                                                    <img src="assets/images/blog-details/comment-img-2.jpg" class="avatar" alt="image">
                                                    <b class="fn">Steven Smith</b>
                                                    <span class="says">says:</span>
                                                </div>
    
                                                <div class="comment-metadata">
                                                    <a routerLink="/blog-details"><span>April  24, 2020 at 10:59 am</span></a>
                                                </div>
                                            </footer>
    
                                            <div class="comment-content">
                                                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim</p>
                                            </div>
    
                                            <div class="reply">
                                                <a routerLink="/blog-details" class="comment-reply-link">Reply</a>
                                            </div>
                                        </div>
                                    </li>
                                </ol>
                            </li>

                            <li class="comment">
                                <div class="comment-body">
                                    <footer class="comment-meta">
                                        <div class="comment-author vcard">
                                            <img src="assets/images/blog-details/comment-img-3.jpg" class="avatar" alt="image">
                                            <b class="fn">John Doe</b>
                                            <span class="says">says:</span>
                                        </div>

                                        <div class="comment-metadata">
                                            <a routerLink="/blog-details"><span>April  24, 2020 at 10:59 am</span></a>
                                        </div>
                                    </footer>

                                    <div class="comment-content">
                                        <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type.</p>
                                    </div>

                                    <div class="reply">
                                        <a routerLink="/blog-details" class="comment-reply-link">Reply</a>
                                    </div>
                                </div>
                            </li>
                        </ol>

                        <div class="comment-respond">
                            <h3 class="comment-reply-title">Leave a Reply</h3>

                            <form class="comment-form">
                                <p class="comment-notes">
                                    <span id="email-notes">Your email address will not be published.</span>
                                    Required fields are marked 
                                    <span class="required">*</span>
                                </p>

                                <p class="comment-form-author">
                                    <label>Name <span class="required">*</span></label>
                                    <input type="text" id="author" name="author" required="required">
                                </p>

                                <p class="comment-form-email">
                                    <label>Email <span class="required">*</span></label>
                                    <input type="email" id="email" name="email" required="required">
                                </p>

                                <p class="comment-form-url">
                                    <label>Website</label>
                                    <input type="url" id="url" name="url">
                                </p>

                                <p class="comment-form-comment">
                                    <label>Comment</label>
                                    <textarea name="comment" id="comment" cols="45" rows="5" maxlength="65525" required="required"></textarea>
                                </p>

                                <p class="form-submit">
                                    <input type="submit" name="submit" id="submit" class="submit" value="Post A Comment">
                                </p>
                            </form>
                        </div>
                    </div> -->
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <aside class="widget-area" id="secondary">
                    <!-- <div class="widget widget_search">
                        <h3 class="widget-title">Search Now</h3>
                        <div class="post-wrap">
                            <form class="search-form">
                                <label>
                                    <span class="screen-reader-text">Search for:</span>
                                    <input type="search" class="search-field" placeholder="Search...">
                                </label>
                                <button type="submit"><i class='bx bx-search'></i></button>
                            </form>
                        </div>
                    </div> -->
                    
                    <section class="widget widget-peru-posts-thumb">
                        <h3 class="widget-title">Facebook Posts</h3>
                        <iframe
                            src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fbattulabalaramakrishna%2F&tabs=timeline&width=417&height=520&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=false&appId=449571520041089"
                            width="417" height="520" style="border:none;overflow:hidden" scrolling="no" frameborder="0"
                            allowfullscreen="true"
                            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                        <!-- <div class="post-wrap">
                            <article class="item">
                                <a routerLink="/blog-details" class="thumb"><span class="fullimage cover bg1" role="img"></span></a>
                                <div class="info">
                                    <time datetime="2020-06-30">April 20, 2020</time>
                                    <h4 class="title usmall"><a routerLink="/blog-details">Restaurant Management & Web Developing</a></h4>
                                </div>
                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a routerLink="/blog-details" class="thumb"><span class="fullimage cover bg2" role="img"></span></a>
                                <div class="info">
                                    <time datetime="2020-06-30">April 21, 2020</time>
                                    <h4 class="title usmall"><a routerLink="/blog-details">Ride Share App UX Studeis & Development Case</a></h4>
                                </div>
                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a routerLink="/blog-details" class="thumb"><span class="fullimage cover bg3" role="img"></span></a>
                                <div class="info">
                                    <time datetime="2020-06-30">April  22, 2020</time>
                                    <h4 class="title usmall"><a routerLink="/blog-details">Parking Management & Web Developing</a> </h4>
                                </div>
                                <div class="clear"></div>
                            </article>
                            
                            <article class="item">
                                <a routerLink="/blog-details" class="thumb"><span class="fullimage cover bg4" role="img"></span></a>
                                <div class="info">
                                    <time datetime="2020-06-30">April 23, 2020</time>
                                    <h4 class="title usmall"><a routerLink="/blog-details">Temperature App UX Studeis & Development Case</a></h4>
                                </div>
                                <div class="clear"></div>
                            </article>
                        </div> -->
                    </section>

                    <section class="widget widget-peru-posts-thumb">
                        <h3 class="widget-title">Twitter Posts</h3>
                        <a class="twitter-timeline"
                            href="https://twitter.com/BattulaBalarama?ref_src=twsrc%5Etfw">Tweets by BattulaBalarama</a>
                        <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>


                    </section>
                    <section class="widget widget_tag_cloud">
                        <h3 class="widget-title">Tags</h3>

                        <div class="post-wrap">
                            <div class="tagcloud">
                                <a style="
                                font-weight: 500;
                                font-family: Ramabhadra, sans-serif !important ;
                                font-size: 34px;
                                " routerLink="/">హోమ్</a>
                                <a style="
                                font-weight: 500;
                                font-family: Ramabhadra, sans-serif !important ;
                                font-size: 34px;
                                " routerLink="/balaramakrishna">మా గురించి</a>
                                <a style="
                                font-weight: 500;
                                font-family: Ramabhadra, sans-serif !important ;
                                font-size: 34px;
                                " routerLink="/sign-up">వాలంటీర్ నమోదు </a>
                                <a style="
                                font-weight: 500;
                                font-family: Ramabhadra, sans-serif !important ;
                                font-size: 34px;
                                " routerLink="/">గ్యాలరీ</a>
                                <a style="
                                font-weight: 500;
                                font-family: Ramabhadra, sans-serif !important ;
                                font-size: 34px;
                                " routerLink="/blog">న్యూస్ & ఈవెంట్స్</a>
                                <a style="
                                font-weight: 500;
                                font-family: Ramabhadra, sans-serif !important ;
                                font-size: 34px;
                                " routerLink="/contact">
                                    కాంట్యాక్ట్</a>
                            </div>
                        </div>
                    </section>
                </aside>
            </div>
        </div>
    </div>
</section>