
<div class="{{navbarClass}}">
    <div class="main-nav" ngStickyNav stickyClass="sticky-box-shadow">
        <div class="container">
            <nav class="navbar navbar-expand-lg navbar-light" [class.active]="classApplied">
                <a class="navbar-brand d-none" routerLink="/">
                    <img src="assets/images/logo2.png" style="width: 125px;" alt="logo">
                </a>
                <button class="navbar-toggler" type="button" (click)="toggleClass()">
                    <span class="burger-menu">
                        <span class="top-bar"></span>
                        <span class="middle-bar"></span>
                        <span class="bottom-bar"></span>
                    </span>
                </button>
                <div class="nav-right">
                    <div class="logo" id="header_wrapper">
                        <a routerLink="/"><img src="assets/images/logo21.png" width="200px" alt="logo" /></a>
                    </div>
                </div>
              
                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav text-left">
                        <li class="nav-item">
                                <a style="font-size: 25px;" routerLink="/" class="nav-link text" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">హోమ్</a>

                           
                        </li>
                        <li class="nav-item">
                        <a style="font-size: 25px;" routerLink="/balaramakrishna" class="nav-link text" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">మా గురించి</a>

                        </li>
                        <li class="nav-item">
                            <a style="font-size: 25px;" routerLink="/volunteer" class="nav-link text" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">వాలంటీర్ నమోదు</a>
                        </li>          
                        <li class="nav-item">
                            <a style="font-size: 25px;" routerLink="/gallery" class="nav-link text" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">గ్యాలరీ</a>                                                    
                        </li>                   
                        <li class="nav-item">
                            <a style="font-size: 25px;" routerLink="/blog" class="nav-link text" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">న్యూస్ & ఈవెంట్స్</a>
                        </li>
                        <li class="nav-item">
                            <a style="font-size: 25px;" routerLink="/contact" class="nav-link text" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">కాంట్యాక్ట్</a>
                        </li>
                        <li class="nav-item">
                            <a style="font-size: 25px;" routerLink="/case-studies" class="nav-link text" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">షెడ్యూల్స్</a>
                        </li>
                        <li class="nav-item">
                            <a style="font-size: 25px;" routerLink="/home-one" class="nav-link text" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">సిద్ధాంతాలు</a>
                        </li>
                        <!-- <li class="nav-item">
                            <a style="font-size: 25px;" routerLink="/case-studies" class="nav-link text" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">షెడ్యూల్స్</a>
                        </li> -->
                        <!-- <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Case Studies</a>
                            
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/case-studies" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Case Studies</a></li>

                                <li class="nav-item"><a routerLink="/case-studies-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Case Studies Details</a></li>
                            </ul>
                        </li> -->

                        <!-- <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Blog</a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/blog" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog</a></li>

                                <li class="nav-item"><a routerLink="/blog-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Details</a></li>
                            </ul>
                        </li> -->

                        <!-- <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Pages</a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/team" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Team</a></li>

                                <li class="nav-item"><a routerLink="/pricing" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Pricing</a></li>

                                <li class="nav-item"><a routerLink="/gallery" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Gallery</a></li>

                                <li class="nav-item"><a routerLink="/testimonials" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Testimonials</a></li>



                                <li class="nav-item"><a routerLink="/error" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Error 404</a></li>

                                <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">FAQ</a></li>

                                <li class="nav-item"><a routerLink="/terms-condition" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Terms & Conditions</a></li>

                                <li class="nav-item"><a routerLink="/privacy-policy" cl ass="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Privacy Policy</a></li>
                            </ul>
                        </li> -->
                    
                        <!-- <li class="nav-item"><a routerLink="/contact" class="nav-link">Contact </a></li> -->
                    </ul>
                </div>
                
               
            </nav>
        </div>
    </div>
</div>