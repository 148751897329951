<section class="home-contact-area home-2-contact ">
    <div class="container">
        <div class="section-title">
            <span style="
            font-weight: 500;
            font-family: Ramabhadra, sans-serif !important ;
            font-size: 34px;
        ">మీ సమస్యలను నమోదు చేయండి</span>
            <h2 class="texts" style="
        font-weight: 500;
        font-family: Ramabhadra, sans-serif !important ;
        font-size: 24px; ">
                ప్రజాస్వామ్యంలో ప్రజలు బాగుపడాలంటే రాజకీయ జవాబుదారీతనం కావాలి
            </h2>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="content">
                    <form class="row" (ngSubmit)="submitservices()" [formGroup]="serviceForm">
                        <div class="row">
                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="text" class="form-control " id="inputLastName1" formControlName="name"
                                        required placeholder=" పేరు" autocomplete="off" />
                                </div>
                            </div>
                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="tel" class="form-control " id="inputPhoneNo" formControlName="age"
                                        required placeholder=" వయస్సు" autocomplete="off" maxlength="2" minlength="2"
                                        class="form-control" (keypress)="numberOnlyValidation($event)"
                                        onkeyup="this.value = this.value.replace(/[^a-zA-Z0-9]/g, '')" />
                                </div>
                            </div>
                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="tel" name="phonenumber" id="phonenumber" formControlName="phonenumber"
                                        required class="form-control" placeholder="ఫోన్ నెంబర్" autocomplete="off"
                                        maxlength="10" minlength="10" class="form-control"
                                        (keypress)="numberOnlyValidation($event)"
                                        onkeyup="this.value = this.value.replace(/[^a-zA-Z0-9]/g, '')" />
                                </div>
                            </div>
                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="text" class="form-control " id="inputLastName1"
                                        formControlName="mandal" required placeholder="మండలం" autocomplete="off" />
                                </div>
                            </div>
                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="text" class="form-control " id="inputLastName1"
                                        formControlName="village" required placeholder="విలేజ్" autocomplete="off" />
                                </div>
                            </div>
                            <div class="col-md-12 col-sm-12" style="padding-top: 10px;">
                                <div class="form-group">
                                    <input type="text" class="form-control " id="inputLastName1"
                                        formControlName="occupation" placeholder="వృత్తి" autocomplete="off">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <!-- accept="application/pdf,application/msword,
                                application/vnd.openxmlformats-officedocument.wordprocessingml.document" -->
                                <input type="file" accept="image/x-png,image/jpeg" id="imageup" hidden
                                    (change)="onImageChange($event)" formControlName="imageurl">
                                <label style="font-family:Ramabhadra,sans-serif;font-weight:500;font-size: 20px;"
                                    for="imageup" class="form-label" *ngIf="imagesData.length < 3">
                                    <img src="assets/images/upload.png"
                                        style="font-family:Ramabhadra,sans-serif;font-weight:500;margin-right: 20px; margin-left:20px"
                                        width="100px" height="100px" alt=""><br>ఇమేజ్ అప్లొడ్ చెయ్యండి</label><br>
                                <span style="padding-top: 10px;" *ngFor="let im of imagesData">
                                    <img src="{{ im.reviewimg }}" style="height: 50px; width: 50px" />
                                </span>
                                <span *ngFor="let im of imagesData">
                                    <!-- <img [src]=' im.filetype == "pdf" ?    "/assets/images/pdf.png" : "/assets/images/word.png" '
                                        style="height:100px;width:100px;padding:10px;"> -->
                                    <i class="fa fa-close" (click)="deleteImage(im)"></i>
                                </span>
                            </div>
                            <div class="col-lg-6 col-md-12">
                                <div class="form-group">
                                    <textarea class="form-control" id="inputAddress3" formControlName="remarks"
                                        cols="30" rows="5" required placeholder="మీ సమస్యను వివరించండి"></textarea>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn page-btn box-btn">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="contact-img contact-img-2">
                    <img src="assets/images/contact.png" alt="contact" />
                </div>
            </div>
        </div>
    </div>
</section>

<!--  -->