<app-navbar2></app-navbar2>
<br>
<br>

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <img src="" alt="">

            <!-- <h2 style="position: relative; top: 135px;">Gallery</h2> -->
            <!-- <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Gallery</li>
            </ul> -->
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="project-area-six pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span  style="
            font-weight: 500;
            font-family: Ramabhadra, sans-serif !important ;
            font-size: 34px;
        "
        >ఫొటో గ్యాలరి</span>
        <h2
        class="texts"
        style="
        font-weight: 500;
        font-family: Ramabhadra, sans-serif !important ;
        font-size: 24px;
        ">
        ప్రజాస్వామ్యంలో ప్రజలు బాగుపడాలంటే రాజకీయ జవాబుదారీతనం కావాలి
        </h2>
        </div>
        <div class="project-wraps">
            <div class="row">
                <div class="col-lg-4 col-md-6" *ngFor="let item of gallery | paginate: { itemsPerPage: 6, currentPage: page }  ">
                    <div class="">
                            <div class="">
                            <a routerLink="/gallerydetails/{{item.id}}" class="image-popup">
                                <img [src]="item.image" class="img-fluid" style="box-shadow: 0 0 0 4px rgb(144 144 144 / 55%);" alt="">
                            </a>
                            <div  style="margin-top: 30px;
                            font-weight: 500;
                            font-family: Ramabhadra, sans-serif !important ;
                            font-size: 24px;">
                            <h3>{{item.categoryname}}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="d-flex justify-content-center pt-100 ">
    <pagination-controls class="my-pagination" (pageChange)="page = $event" responsive="true" previousLabel="< Prev" nextLabel="Next >">
    </pagination-controls>
</div>
<!-- <div class="col-lg-12">
    <div class="page-navigation-area">
        <nav aria-label="Page navigation example text-center">
            <ul class="pagination">
                <li class="page-item"><a class="page-link page-links" routerLink="/blog"><i class='bx bx-chevrons-left'></i></a></li>
                <li class="page-item active"><a class="page-link" routerLink="/blog">1</a></li>
                <li class="page-item"><a class="page-link" routerLink="/blog">2</a></li>
                <li class="page-item"><a class="page-link" routerLink="/blog">3</a></li>
                <li class="page-item"><a class="page-link" routerLink="/blog"><i class='bx bx-chevrons-right'></i></a></li>
            </ul>
        </nav>
    </div>
</div> -->

<style>
    .my-pagination{
      font-size: 18px;
    }
    .my-pagination ::ng-deep .ngx-pagination .current {
      background: red;
      border-radius: 100%;
      padding-left: 12px;
      padding-right: 12px;
      font-weight: 500;
/* letter-spacing: 20px; */

      border: transparent;
    }
    :host ::ng-deep .ngx-pagination .current:hover {
  background: #17a2b8 !important;
  border-radius: 20px;
  border: transparent;
}
  </style>
