import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';

@Component({
  selector: 'app-navbar2',
  templateUrl: './navbar2.component.html',
  styleUrls: ['./navbar2.component.scss']
})
export class Navbar2Component {
  providers: [
    Location, {
        provide: LocationStrategy,
        useClass: PathLocationStrategy
    }
]

  location: any;
  navbarClass: any;

  classApplied = false;
  toggleClass() {
      this.classApplied = !this.classApplied;
  }

  constructor(
      private router: Router,
      location: Location
  ) {
      this.router.events
      .subscribe((event) => {
          if ( event instanceof NavigationEnd ) {
              this.location = this.router.url;
              if (this.location == '/home-three'){
                  this.navbarClass = 'navbar-area three';
              } else {
                  this.navbarClass = 'navbar-area';
              }
          }
      });
  }

  ngOnInit(): void {
  }

}