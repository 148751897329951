import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { delay, map } from 'rxjs/operators';
import Swal from 'sweetalert2';

var sapi='http://localhost:8080/nodeapp'

var Api = 'https://battulabalaramakrishna.com:2479/dashboardapi/';

@Injectable({
  providedIn: 'root'
})
export class GalleryService {
  errorMessageAlert(arg0: string) {
      throw new Error('Method not implemented.');
  }
  constructor(private httpClient: HttpClient) { }
  getBannerImages() {
    return this.httpClient.get<any>(Api + `gethomebanners`)
  }
  getHomeVideoData() {
    return this.httpClient.get<any>(Api + `gethomevideodata`)
  }
  getEventData() {
    return this.httpClient.get<any>(Api + `geteventpostingdata`)
  }
  getgallery(id: any) {
    return this.httpClient.get(Api + 'getgallery/' + id)
  }
  getgallerycategory() {
    return this.httpClient.get(Api + 'getCatenamedata')
  }
  getvideourldata(){
    console.log("hii");
    return this.httpClient.get(Api + 'getvideourldata')
  }
  getvideourldatadetails(id: any) {
    return this.httpClient.get(Api + 'getvideourldatadetails/' + id)
  }
  postcareers(data){
    return this.httpClient.post<any>(Api + `/careers`,data)
  }
  submitupdate(data) {
    return this.httpClient.post<any>(Api + 'submitregistrationdata', data).pipe(map(res => {
      return res;
    }, (error: any) => {
      return error;
    }));
  }
  getEventscheduleData() {
    return this.httpClient.get<any>(Api + `getscheduledata`)
  }
}


