import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { GalleryService } from '../../services/gallery.service';

@Component({
  selector: 'app-case-studies',
  templateUrl: './case-studies.component.html',
  styleUrls: ['./case-studies.component.scss']
})
export class CaseStudiesComponent implements OnInit {
  events: any;
  dateTime: Date;
  constructor(private gallerys: GalleryService,private sanitizer: DomSanitizer) { }
  ngOnInit(): void {
    this.dateTime = new Date()
    this.getEventDatas();
  }
  getEventDatas(){
    console.log("pawan");
    this.gallerys.getEventscheduleData().subscribe((res: any) => {
      console.log(res.data);   
      this.events = res.data;       
      // location.reload();         
    })
  }
}
