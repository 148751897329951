<app-navbar2></app-navbar2>
<br>
<br>
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <img src="" alt="">
            <!-- <h2 style="position: relative; top: 135px;">Gallery</h2> -->
            <!-- <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Gallery</li>
            </ul> -->
        </div>
    </div>
    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>
<section class="home-blog-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span style="
            font-weight: 500;
            font-family: Ramabhadra, sans-serif !important ;
            font-size: 34px;
        "> న్యూస్ & ఈవెంట్స్</span>
            <h2 class="texts" style="
        font-weight: 500;
        font-family: Ramabhadra, sans-serif !important ;
        font-size: 24px;
        ">
                ప్రజాస్వామ్యంలో ప్రజలు బాగుపడాలంటే రాజకీయ జవాబుదారీతనం కావాలి
            </h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6"
                *ngFor="let item of blogpost | paginate: { itemsPerPage: 3, currentPage: page }">
                <div class="single-blog">
                    <div class="blog-img">
                        <img src="{{item.image}}" alt="" style="width: 416px; height: 300px;">
                        <!-- <iframe [src]="item.banner_url" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen class="img-fluid" style="width: 416px; height: 250px;"></iframe> -->
                    </div>
                    <div class="content">
                        <h3 class="texts" style="
                        font-weight: 500;
                        font-family: Ramabhadra, sans-serif !important ;
                        font-size: 34px;
                        "><a routerLink="/blog-details/{{item.id}}">{{item.titlename}}</a></h3>
                        <span class="texts" style="
                        font-weight: 500;
                        font-family: Ramabhadra, sans-serif !important ;
                        font-size: 18px;
                        "> <a>{{item.speaker}}</a></span>
                        <a routerLink="/blog-details/{{item.id}}" class="line-bnt"> Read More</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="page-navigation-area">
                    <pagination-controls class="my-pagination" (pageChange)="page = $event" responsive="true"
                        previousLabel="< Prev" nextLabel="Next >">
                    </pagination-controls>
                </div>
            </div>
        </div>
    </div>
</section>