import { Component } from '@angular/core';
import { GalleryService } from '../../services/gallery.service';

@Component({
  selector: 'app-banners',
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.scss']
})
export class BannersComponent {
  slides: any = [];

  constructor(private bannerService: GalleryService) {
    this.getBannerImages();
    console.log(this.slides);
  }
  getBannerImages() {
    this.bannerService.getBannerImages().subscribe((imagedata: any) => {
      console.log(imagedata);
      this.slides = imagedata.data;
    }, (error: any) => {
      console.log(error)
    })
  }
  ngOnInit(): void {
  }
}


